<template lang="pug">
    li(@click="$emit('setContent', contents)")#presidente
        h4 {{ contents.name.pt }}
        span {{ contents.role.pt }}
        span {{ contents.period.pt }}
        div.abstract
            Imagem(:contents="contents.image", v-if="contents.image").img
            p {{ contents.content.pt | abstract }}
            p.plus ler mais
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'component-presidente',
  props,
  components,
  filters: {
    abstract (texto) {
      const sanitizedContent = new DOMParser().parseFromString(texto, 'text/html').body.textContent
      if (sanitizedContent.length >= 100) { return sanitizedContent.slice(0, 100) + '...' } else { return sanitizedContent }
    }
  }
}
</script>

<style lang="stylus" scoped src="./Presidente.styl"></style>
